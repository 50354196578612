ualter-sound {
  --primary-color: #535353;
  --secondary-color: white;
  --main-container-background: #f9f8f8;
  /* --speech-container-background: #ff00002; */
  --speech-container-background: #f9f7f7;
  --speech-highlighted-word: yellow;
  --audio-player-background: #e1e1e1;
  --audio-player-color: #383838;
  --audio-player-seek-slider-border: rgba(0, 0, 0, 0.5);
}
