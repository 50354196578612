@import "colors.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "flowbite";

@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Lora:ital,wght@0,400..700;1,400..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

@layer base {
  ualter-sound {
    @apply text-base antialiased;
    margin: 0 auto;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

[data-testid="flowbite-accordion-heading"] {
  width: 100%;
}

ualter-sound,
.ualter-sound-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
}

.ualter-sound-audio-player,
.ualter-sound-audio-volume {
  background-color: var(--audio-player-background);
  color: var(--audio-player-color);
}

/****Audio player volume bar ***/

#ualter-sound-audio-player-volume {
  background-color: var(--audio-player-background);
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 75px;
}

#ualter-sound-audio-player-volume {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
}

#ualter-sound-audio-player-volume::-webkit-slider-runnable-track {
  background-color: var(--audio-player-color);
  height: 4px;
  margin-bottom: 0.5px;
  border-radius: 20px;
}

#ualter-sound-audio-player-volume::-moz-range-track {
  background-color: var(--audio-player-color);
  height: 4px;
  border-radius: 20px;
}

#ualter-sound-audio-player-volume::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -6px;
  background-color: var(--audio-player-color);
  height: 15px;
  width: 15px;
}

#ualter-sound-audio-player-volume::-moz-range-thumb {
  border: none;
  background-color: var(--audio-player-color);
  height: 15px;
  width: 15px;
}

/****Audio player seek bar ***/

#ualter-sound-audio-player-seek {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
}

#ualter-sound-audio-player-seek::-webkit-slider-runnable-track {
  background-color: var(--audio-player-color);
  height: 4px;
  border-radius: 20px;
}

#ualter-sound-audio-player-seek::-moz-range-track {
  background-color: var(--audio-player-color);
  height: 4px;
  border-radius: 20px;
}

#ualter-sound-audio-player-seek::-moz-range-progress,
#ualter-sound-audio-player-volume::-moz-range-progress {
  background-color: var(--audio-player-color);
}

#ualter-sound-audio-player-seek::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -10px;
  background-color: var(--audio-player-color);
  height: 25px;
  /* Increased height for oval shape */
  width: 10px;
  /* Reduced width for oval shape */
  border-radius: 10px;
  /* Ensures the thumb is oval */
  border: 1px solid var(--audio-player-seek-slider-border);
  /* Add border */
}

#ualter-sound-audio-player-seek::-moz-range-thumb {
  border: none;
  background-color: var(--audio-player-color);
  height: 25px;
  /* Increased height for oval shape */
  width: 10px;
  /* Reduced width for oval shape */
  border-radius: 10px;
  /* Ensures the thumb is oval */
  border: 1px solid var(--audio-player-seek-slider-border);
  /* Add border */
}

/*******/

@media (hover: hover) and (pointer: fine) {
  .ualter-sound-audio-volume-container:hover .ualter-sound-audio-volume {
    display: grid;
  }
}

.ualter-sound-audio-volume:hover {
  display: grid;
}

/* 
  The following 4 styles are for the audio player buttons but should
  be replaced by behavior in the reactjs component.
*/
.ualter-sound-quote-pause {
  display: none;
}

.ualter-sound-quote-play {
  display: block;
}

.ualter-sound-quote-playing .ualter-sound-quote-pause {
  display: block;
}

.ualter-sound-quote-playing .ualter-sound-quote-play {
  display: none;
}

/* Superseeded by .ualter-sound-speech-highlightable .ualter-sound-is-playing 
    for simplicity*/
/*
 .ualter-sound-quote-playing .ualter-sound-is-playing {
  background-color: yellow;
} */

/* Changed from #ualter-sound-container-discurso to not use hard ids, 
    check if it is the proper selector */
.ualter-sound-speech-highlightable .ualter-sound-is-playing {
  background-color: var(--speech-highlighted-word);
}

.ualter-sound-speech-container {
  background-color: var(--speech-container-background);
}

ualter-sound {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fade-exit {
  opacity: 0;
  transform: translateY(20px);
}

.fade-exit-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.fade-enter {
  opacity: 1;
  transform: translateY(0);
}

.fade-enter-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 300ms, transform 300ms;
}

.ualter-sound-nav-item-underline {
  background-color: var(--primary-color);
}

.ualter-sound-share-button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-color: var(--primary-color);
}

.ualter-sound-main-container {
  background-color: var(--main-container-background);
  border-color: var(--primary-color);
}

.ualter-sound-nav-hr {
  border-color: var(--primary-color);
}

.ualter-sound-header-link,
.ualter-sound-keep-listening {
  color: var(--primary-color);
}

.ualter-sound-svg-background,
.ualter-sound-svg-primary,
.ualter-sound-spinner {
  fill: var(--primary-color);
}

.ualter-sound-svg-secondary {
  fill: var(--secondary-color);
}

.ualter-sound-svg-audio-player-background {
  fill: var(--audio-player-color);
}

.ualter-sound-svg-audio-player {
  fill: var(--audio-player-color);
  stroke: var(--audio-player-color);
}

.ualter-sound-svg-audio-player-play {
  fill: var(--audio-player-background);
}

.ualter-sound-action-button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}
